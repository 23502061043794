import { useState, useRef } from 'react';
import {
  Box,
  Input,
  Popover,
  PopoverContent,
  PopoverTrigger,
  useDisclosure,
  useOutsideClick,
} from '@chakra-ui/react';
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file
import { format } from 'date-fns';
import es from 'date-fns/locale/es';
import { DateRangePicker, Range } from 'react-date-range';

interface Props {
  setDate: (range: Range) => void;
  defaultStartDate: Date;
  defaultEndDate: Date;
}

const DatePicker = ({ setDate, defaultStartDate, defaultEndDate }: Props) => {
  const defaultDate = {
    startDate: defaultStartDate,
    endDate: defaultEndDate,
  };

  const [range, setRange] = useState<Range>({
    ...defaultDate,
    key: 'selection',
  });

  const handleSetDate = (range: Range) => {
    setRange(range);
    setDate(range);
  };

  const { isOpen, onOpen, onClose } = useDisclosure();

  const initialRef = useRef(null);
  const calendarRef = useRef(null);

  useOutsideClick({
    ref: calendarRef,
    handler: onClose,
    enabled: isOpen,
  });

  return (
    <Box>
      <Popover
        placement="bottom"
        isOpen={isOpen}
        onClose={onClose}
        initialFocusRef={initialRef}
        isLazy
      >
        <PopoverTrigger>
          <Box onClick={onOpen} ref={initialRef}>
            <Input
              focusBorderColor="brand.400"
              shadow="sm"
              size="sm"
              w="full"
              rounded="md"
              value={
                range.startDate && range.endDate
                  ? `${format(range.startDate, 'dd/MM/yyyy')} - ${format(
                      range.endDate,
                      'dd/MM/yyyy'
                    )}`
                  : 'Desde - Hasta'
              }
              onChange={() => {}}
            />
          </Box>
        </PopoverTrigger>

        <PopoverContent
          p={0}
          w="min-content"
          border="none"
          outline="none"
          _focus={{ boxShadow: 'none' }}
          ref={calendarRef}
        >
          <DateRangePicker
            direction="vertical"
            inputRanges={[]}
            locale={es}
            months={1}
            moveRangeOnFirstSelection={false}
            ranges={[range]}
            onChange={(item) => handleSetDate(item.selection)}
          />
        </PopoverContent>
      </Popover>
    </Box>
  );
};

export default DatePicker;
