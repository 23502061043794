import { Stack } from '@chakra-ui/react';

interface CardProps {
  children: React.ReactNode;
}

const Card = ({ children }: CardProps): JSX.Element => {
  return (
    <Stack
      bg="white"
      p="6"
      borderRadius="lg"
      spacing="10"
      mt="2"
      direction={{ base: 'column', md: 'row' }}
      justifyContent="space-between"
    >
      {children}
    </Stack>
  );
};

export default Card;
