import { useState } from 'react';
import {
  Flex,
  Box,
  FormControl,
  FormLabel,
  Input,
  Image,
  Stack,
  Button,
  useColorModeValue,
  useToast,
} from '@chakra-ui/react';
import logo from '../resources/logo.png';
import { post } from '../utils/fetchApi';
import { useNavigate } from 'react-router-dom';
import { useAuthContext } from '../hooks/useAuthContext';
import { useForm } from 'react-hook-form';

export default function Login() {
  const { updateToken } = useAuthContext();
  const navigate = useNavigate();
  const toast = useToast();
  const {
    handleSubmit,
    register,
    formState: { errors, isSubmitting },
    reset,
  } = useForm({
    reValidateMode: 'onChange',
  });

  const handleLogin = async (values) => {
    console.log(values);
    try {
      const res = await post<AuthResponse>(
        `${process.env.REACT_APP_API_URL}/auth/login`,
        values
      );

      if (!res.data) {
        throw new Error('error');
      }

      updateToken(res.data?.accessToken);
      navigate('/');
    } catch (error) {
      toast({
        title: `Error. Usuario o contraseña incorrectos`,
        status: 'error',
        isClosable: true,
        position: 'top',
      });
    }
  };

  return (
    <Flex
      minH={'100vh'}
      align={'center'}
      justify={'center'}
      bg={useColorModeValue('cyan.500', 'cyan.100')}
    >
      <Stack spacing={8} mx={'auto'} py={12} px={6} w={'xl'}>
        <Stack align={'center'}>
          {/* <Heading fontSize={'4xl'}>Iniciar sesión</Heading> */}
          <Image src={logo} w="auto" h="100px" />
        </Stack>
        <Box
          rounded={'lg'}
          bg={useColorModeValue('white', 'gray.700')}
          boxShadow={'lg'}
          p={8}
        >
          <form onSubmit={handleSubmit(handleLogin)}>
            <Stack spacing={4}>
              <FormControl
                id="usernameOrEmail"
                isInvalid={!!errors.usernameOrEmail}
              >
                <FormLabel>Email</FormLabel>
                <Input
                  type="email"
                  {...register('usernameOrEmail', {
                    required: 'This is required',
                  })}
                />
              </FormControl>
              <FormControl id="password" isInvalid={!!errors.password}>
                <FormLabel>Contraseña</FormLabel>
                <Input
                  type="password"
                  {...register('password', {
                    required: 'This is required',
                  })}
                />
              </FormControl>
              <Stack spacing={10}>
                <Button
                  bg={'cyan.500'}
                  color={'white'}
                  _hover={{
                    bg: 'cyan.600',
                  }}
                  type="submit"
                  isLoading={isSubmitting}
                >
                  Ingresar
                </Button>
              </Stack>
            </Stack>
          </form>
        </Box>
      </Stack>
    </Flex>
  );
}
