import { extendTheme, ThemeConfig } from '@chakra-ui/react';
import foundations from './foundations';

export const config: ThemeConfig = {
  initialColorMode: 'light',
  useSystemColorMode: false,
};

export const styles = {
  global: (props: any) => ({
    html: {
      bg: 'gray.50',
    },
    body: {
      bg: 'gray.50',
      WebkitTapHighlightColor: 'transparent',
      fontSize: 'xs',
      color: '#8c8f93',
    },
    '#chakra-toast-portal > *': {
      pt: 'safe-top',
      pl: 'safe-left',
      pr: 'safe-right',
      pb: 'safe-bottom',
    },
  }),
};

export default extendTheme({
  config,
  styles,
  ...foundations,
});
