import { useEffect } from 'react';
import { Flex } from '@chakra-ui/react';
import { Outlet, useLocation } from 'react-router-dom';
import { Viewport } from './Viewport';
import Navbar from './Nabvar';
import { SearchProvider } from '../../context/SearchContext';

export const Layout = () => {
  const { pathname } = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return (
    <SearchProvider>
      <Viewport>
        <Navbar />
        <Flex flex="1" direction="column" m={4}>
          <Outlet />
        </Flex>
      </Viewport>
    </SearchProvider>
  );
};
