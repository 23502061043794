import { AUTH_TOKEN_KEY } from "../hooks/useAuthContext";


interface HttpResponse<T> extends Response {
  data?: T;
}

export const getAccessToken = (
  options: RequestInit,
  storage: Storage = localStorage
) => {
  const accessToken = storage.getItem(AUTH_TOKEN_KEY);
  return {
    ...options,
    headers: {
      ...options.headers,
      ...(accessToken && { Authorization: `Bearer ${accessToken}` })
    }
  };
};

export async function http<T>(request: RequestInfo): Promise<HttpResponse<T>> {
  const response: HttpResponse<T> = await fetch(request);

  try {
    response.data = await response.json();
  } catch (ex) {}

  if (!response.ok) {
    throw new Error(response.statusText);
  }
  return response;
}

export async function get<T>(
  path: string,
  args: RequestInit = {
    method: 'GET',
    headers: { 'Content-Type': 'application/json' },
  }
): Promise<HttpResponse<T>> {
  args = getAccessToken(args);
  return await http<T>(new Request(path, args));
}

export async function post<T>(
  path: string,
  body: any,
  args: RequestInit = {
    method: 'POST',
    body: JSON.stringify(body),
    headers: { 'Content-Type': 'application/json' },
  }
): Promise<HttpResponse<T>> {
  args = getAccessToken(args);
  return await http<T>(new Request(path, args));
}

export async function patch<T>(
  path: string,
  body: any,
  args: RequestInit = {
    method: 'PATCH',
    body: JSON.stringify(body),
    headers: { 'Content-Type': 'application/json' },
  }
): Promise<HttpResponse<T>> {
  args = getAccessToken(args);
  return await http<T>(new Request(path, args));
}
