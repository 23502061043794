import { Routes, Route } from 'react-router-dom';
import Home from '../pages/Home';
import Detail from '../pages/Detail';
import NotFound from '../pages/NotFound';
import { AuthProvider } from '../hooks/useAuthContext';
import { Layout } from '../components/Layout';
import Login from '../pages/Login';
import PrivateOutlet from './PrivateOutlet';

const Router = () => (
  <AuthProvider>
    <Routes>
      <Route element={<PrivateOutlet />}>
        <Route element={<Layout />}>
          <Route path="/" element={<Home />} />
          <Route path="/detail/:id" element={<Detail />} />
        </Route>
      </Route>
      <Route path="/login" element={<Login />} />
      <Route path="*" element={<NotFound />} />
    </Routes>
  </AuthProvider>
);

export default Router;
