import { Link } from 'react-router-dom';
import { Text, Center, VStack, Button } from '@chakra-ui/react';

const NotFound = () => {
  return (
    <Center bg="white" h="100vh" color="tomato">
      <VStack>
        <Text fontSize="6xl">404</Text>
        <Text fontSize="xl">Página no encontrada!</Text>
        <Button colorScheme="tomato" variant='outline'>
          <Link to="/">Ir a inicio</Link>
        </Button>
      </VStack>
    </Center>
  );
};

export default NotFound;
