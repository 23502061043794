import { FC } from 'react';
import { Badge, BadgeProps, Tooltip } from '@chakra-ui/react';

interface Props extends BadgeProps {
  status: Status;
}

const StatusBadge: FC<Props> = ({ status, ...rest }: Props) => {
  const mapStatusToColor = {
    PROCESS_ERROR: 'red',
    PENDING: 'purple',
    PROCESS_OK: 'green',
    ERROR_ROSSI_SERVICES: 'red',
  };

  const mapHelp = {
    PROCESS_ERROR: 'Revisar los datos. Al guardar se pondrá pendiente de procesar.',
    PENDING: 'Se procesará a la brevedad.',
    PROCESS_OK: 'Se procesó correctamente',
    ERROR_ROSSI_SERVICES: 'Hubo un error en los datos de origen (Wellbin)',
  };

  return (
    <Tooltip label={mapHelp[status]} fontSize="md">
      <Badge {...rest} colorScheme={mapStatusToColor[status]}>
        {status}
      </Badge>
    </Tooltip>
  );
};

export default StatusBadge;
