import React, {
  useState,
  createContext,
  useContext,
  useEffect,
  useRef,
  useReducer,
} from 'react';
import { endOfMonth, startOfYear, subYears } from 'date-fns';
import { post } from '../utils/fetchApi';

interface State {
  data?: ResponseBody;
  error?: Error;
  status: 'idle' | 'loading' | 'error' | 'fetched';
}

type Cache = { [url: string]: ResponseBody };

type Action =
  | { type: 'loading' }
  | { type: 'fetched'; payload: ResponseBody }
  | { type: 'error'; payload: Error };

interface Filters {
  firstName: string | undefined;
  lastName: string | undefined;
  startDate: Date;
  endDate: Date;
  status: string | undefined;
  laboratory: string | undefined;
  barcode: string | undefined;
  withError?: boolean;
  identificationNumber: string | undefined;
  page: number;
  size: number;
}

interface ContextProps {
  filters: Filters;
  setFilters: React.Dispatch<React.SetStateAction<Filters>>;
  cleanFilters: () => void;
  getData: () => void;
  state: State;
}

export const SearchContext = createContext<ContextProps | undefined>(undefined);

export const initialFilters = {
  firstName: '',
  lastName: '',
  startDate: startOfYear(new Date()),
  endDate: endOfMonth(new Date()),
  status: "ALL",
  laboratory: undefined,
  barcode: '',
  withError: undefined,
  identificationNumber: '',
  page: 1,
  size: 30,
};

export function SearchProvider({ children }: { children: React.ReactNode }) {
  const [filters, setFilters] = useState<Filters>(initialFilters);
  const cleanFilters = () => setFilters(initialFilters);
  const cache = useRef<Cache>({});
  const url = `${process.env.REACT_APP_API_URL}/rossi/get-all-data/${filters?.page}/${filters?.size}`;

  const initialState: State = {
    error: undefined,
    data: undefined,
    status: 'idle',
  };

  const fetchReducer = (state: State, action: Action): State => {
    switch (action.type) {
      case 'loading':
        return { ...initialState, status: action.type };
      case 'fetched':
        return { ...initialState, data: action.payload, status: action.type };
      case 'error':
        return { ...initialState, error: action.payload, status: action.type };
      default:
        return state;
    }
  };

  const [state, dispatch] = useReducer(fetchReducer, initialState);

  const getData = async () => {
    dispatch({ type: 'loading' });

    // if (cache.current[url]) {
    //   dispatch({ type: 'fetched', payload: cache.current[url] });
    //   return;
    // }

    try {
      const response = await post<ResponseBody>(url, filters);
      if (!response.ok) {
        throw new Error(response.statusText);
      }
      const data = response.data as ResponseBody;
      cache.current[url] = data;
      dispatch({ type: 'fetched', payload: data });
    } catch (error) {
      dispatch({ type: 'error', payload: error as Error });
    }
  };

  useEffect(() => {
    getData();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters]);

  const value = { filters, setFilters, cleanFilters, getData, state };

  return (
    <SearchContext.Provider value={value}>{children}</SearchContext.Provider>
  );
}

export function useFilters() {
  const context = useContext(SearchContext);
  if (context === undefined) {
    throw new Error('useFilter must be used within a SearchProvider');
  }
  return context;
}
