import { ChakraProvider } from '@chakra-ui/react';
import { Suspense } from 'react';
import { Loader } from './components/Layout/Loader';
import Router from './routes';
import theme from './theme';

export const App = () => (
  <ChakraProvider theme={theme}>
    <Suspense fallback={<Loader />}>
      <Router />
    </Suspense>
  </ChakraProvider>
);
