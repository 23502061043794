import {
  Box,
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  Button,
  Divider,
  FormControl,
  FormLabel,
  GridItem,
  HStack,
  Input,
  Select,
  SimpleGrid,
  Text,
  Textarea,
  Tooltip,
  useToast,
} from '@chakra-ui/react';
import Card from '../components/Card';
import { useForm } from 'react-hook-form';
import { useParams, useNavigate } from 'react-router-dom';
import { useDetail, updateData } from '../hooks/useDetail';
import { useEffect, useState } from 'react';
import StatusBadge from '../components/StatusBadge';
import { FiArrowLeft } from 'react-icons/fi';
import { get } from '../utils/fetchApi';

const url = `${process.env.REACT_APP_API_URL}/rossi/get-data/`;
const reprocessUrl = `${process.env.REACT_APP_API_URL}/sisa/reprocess-data-errors/`;

const Detail = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const toast = useToast();
  const [time, setTime] = useState(new Date().getTime());
  const [reprocessing, setReprocessing] = useState(false);
  const { status, error, data, dispatch } = useDetail(
    `${url}${id}?time=${time}`
  );
  const {
    handleSubmit,
    register,
    formState: { errors },
    reset,
  } = useForm({
    reValidateMode: 'onChange',
  });

  useEffect(() => {
    if (data) reset(data);
  }, [data, reset]);

  const onSubmit = async (values: any) => {
    if (data?.status !== 'PROCESS_OK') {
      await updateData(
        `${url}${id}`,
        { ...values, status: 'PENDING' },
        dispatch
      )
        .then((res) => {
          console.log(res);
          toast({
            title: `Registro actualizado`,
            status: 'success',
            isClosable: true,
          });
        })
        .catch((err) => {
          console.log(error);
          toast({
            title: `Error al actualizar el registro`,
            status: 'error',
            isClosable: true,
          });
        });
    }
  };

  const reProcess = async () => {
    setReprocessing(true);
    await get<PathResponse>(`${reprocessUrl}${id}`)
      .then((res) => {
        console.log(res);
        toast({
          title: `Registro procesado`,
          status: 'success',
          isClosable: true,
        });
      })
      .catch((err) => {
        console.log(err);
        toast({
          title: `Error al procesar el registro`,
          status: 'error',
          isClosable: true,
        });
      })
      .finally(() => {
        setTime(new Date().getTime());
        setReprocessing(false);
      });
  };

  return (
    <Box>
      <Breadcrumb>
        <BreadcrumbItem fontSize="lg" fontWeight={400}>
          <BreadcrumbLink onClick={() => navigate('/')}>Inicio</BreadcrumbLink>
        </BreadcrumbItem>
        <BreadcrumbItem isCurrentPage fontSize="md" fontWeight={300}>
          <BreadcrumbLink href="#">
            {data?.firstName} {data?.lastName}
          </BreadcrumbLink>
        </BreadcrumbItem>
      </Breadcrumb>
      <Card>
        <form
          onSubmit={handleSubmit(onSubmit)}
          style={{ width: '100%', padding: '10px' }}
        >
          <SimpleGrid
            display={{ base: 'initial', md: 'grid' }}
            columns={{ sm: 12, md: 24 }}
            spacing={{ md: 2 }}
          >
            <GridItem colSpan={[24]} mb={3}>
              <Text fontSize="lg"> Datos del paciente </Text>
              <Divider />
            </GridItem>

            <FormControl
              isInvalid={!!errors.firstName}
              as={GridItem}
              colSpan={[12, 6]}
            >
              <FormLabel
                htmlFor="firstName"
                fontSize="sm"
                fontWeight="md"
                color="gray.700"
              >
                Nombre
              </FormLabel>
              <Input
                type="text"
                focusBorderColor="brand.400"
                shadow="sm"
                size="sm"
                w="full"
                rounded="md"
                {...register('firstName', {
                  required: 'This is required',
                })}
              />
            </FormControl>

            <FormControl
              isInvalid={!!errors.lastName}
              as={GridItem}
              colSpan={[12, 6]}
            >
              <FormLabel
                htmlFor="lastName"
                fontSize="sm"
                fontWeight="md"
                color={'gray.700'}
              >
                Apellido
              </FormLabel>
              <Input
                type="text"
                id="lastName"
                focusBorderColor="brand.400"
                shadow="sm"
                size="sm"
                w="full"
                rounded="md"
                {...register('lastName', {
                  required: 'This is required',
                })}
              />
            </FormControl>

            <FormControl
              isInvalid={!!errors.identificationDocument}
              as={GridItem}
              colSpan={[12, 6]}
            >
              <FormLabel
                htmlFor="identificationDocument"
                fontSize="sm"
                fontWeight="md"
                color={'gray.700'}
              >
                DNI
              </FormLabel>
              <Input
                type="text"
                focusBorderColor="brand.400"
                shadow="sm"
                size="sm"
                w="full"
                rounded="md"
                {...register('identificationDocument', {
                  required: 'This is required',
                })}
              />
            </FormControl>

            <FormControl
              isInvalid={!!errors.birthday}
              as={GridItem}
              colSpan={[12, 6]}
            >
              <FormLabel
                htmlFor="birthday"
                fontSize="sm"
                fontWeight="md"
                color={'gray.700'}
              >
                Fecha de nacimiento
              </FormLabel>
              <Input
                type="text"
                focusBorderColor="brand.400"
                shadow="sm"
                size="sm"
                w="full"
                rounded="md"
                {...register('birthday', {
                  required: 'This is required',
                })}
              />
            </FormControl>

            <FormControl
              isInvalid={!!errors.age}
              as={GridItem}
              colSpan={[12, 6]}
            >
              <FormLabel
                htmlFor="age"
                fontSize="sm"
                fontWeight="md"
                color={'gray.700'}
              >
                Edad
              </FormLabel>
              <Input
                type="text"
                focusBorderColor="brand.400"
                shadow="sm"
                size="sm"
                w="full"
                rounded="md"
                {...register('age', {
                  required: 'This is required',
                })}
              />
            </FormControl>

            <FormControl
              isInvalid={!!errors.gender}
              as={GridItem}
              colSpan={[12, 6]}
            >
              <FormLabel
                htmlFor="gender"
                fontSize="sm"
                fontWeight="md"
                color={'gray.700'}
              >
                Género
              </FormLabel>

              <Select
                id=""
                name="gender"
                autoComplete="gender"
                focusBorderColor="brand.400"
                shadow="sm"
                size="sm"
                w="full"
                rounded="md"
                {...register('gender', {
                  required: 'This is required',
                })}
              >
                <option value="female">Femenino</option>
                <option value="male">Masculino</option>
              </Select>
            </FormControl>

            <FormControl
              isInvalid={!!errors.phone}
              as={GridItem}
              colSpan={[12, 6]}
            >
              <FormLabel
                htmlFor="phone"
                fontSize="sm"
                fontWeight="md"
                color={'gray.700'}
              >
                Teléfono
              </FormLabel>
              <Input
                type="text"
                focusBorderColor="brand.400"
                shadow="sm"
                size="sm"
                w="full"
                rounded="md"
                {...register('phone')}
              />
            </FormControl>
            <FormControl
              isInvalid={!!errors.email}
              as={GridItem}
              colSpan={[12, 6]}
            >
              <FormLabel
                htmlFor="email"
                fontSize="sm"
                fontWeight="md"
                color={'gray.700'}
              >
                Email
              </FormLabel>
              <Input
                type="text"
                focusBorderColor="brand.400"
                shadow="sm"
                size="sm"
                w="full"
                rounded="md"
                {...register('email')}
              />
            </FormControl>

            <GridItem colSpan={[24]} my={3}>
              <Text fontSize="lg"> Datos del estudio </Text>
              <Divider />
            </GridItem>

            <FormControl
              isInvalid={!!errors.barcode}
              as={GridItem}
              colSpan={[12, 6]}
            >
              <FormLabel
                htmlFor="barcode"
                fontSize="sm"
                fontWeight="md"
                color={'gray.700'}
              >
                Número de estudio
              </FormLabel>
              <Input
                type="text"
                focusBorderColor="brand.400"
                shadow="sm"
                size="sm"
                w="full"
                rounded="md"
                {...register('barcode', {
                  required: 'This is required',
                })}
              />
            </FormControl>

            <FormControl
              isInvalid={!!errors.loadLaboratory}
              as={GridItem}
              colSpan={[12, 6]}
            >
              <FormLabel
                htmlFor="loadLaboratory"
                fontSize="sm"
                fontWeight="md"
                color={'gray.700'}
              >
                Laboratorio
              </FormLabel>
              <Input
                type="text"
                focusBorderColor="brand.400"
                shadow="sm"
                size="sm"
                w="full"
                rounded="md"
                {...register('loadLaboratory', {
                  required: 'This is required',
                })}
              />
            </FormControl>

            <FormControl
              isInvalid={!!errors.sampleDate}
              as={GridItem}
              colSpan={[12, 6]}
            >
              <FormLabel
                htmlFor="sampleDate"
                fontSize="sm"
                fontWeight="md"
                color={'gray.700'}
              >
                Fecha del estudio
              </FormLabel>
              <Input
                type="text"
                focusBorderColor="brand.400"
                shadow="sm"
                size="sm"
                w="full"
                rounded="md"
                {...register('sampleDate', {
                  required: 'This is required',
                })}
              />
            </FormControl>

            <FormControl
              isInvalid={!!errors.sampleResult}
              as={GridItem}
              colSpan={[12, 6]}
            >
              <FormLabel
                htmlFor="sampleResult"
                fontSize="sm"
                fontWeight="md"
                color={'gray.700'}
              >
                Resultado
              </FormLabel>
              <Select
                id=""
                name="sampleResult"
                autoComplete="sampleResult"
                focusBorderColor="brand.400"
                shadow="sm"
                size="sm"
                w="full"
                rounded="md"
                {...register('sampleResult', {
                  required: 'This is required',
                })}
              >
                <option value="Detectable">Detectable</option>
                <option value="No Detectable">No Detectable</option>
                <option value="Detectable">Positivo</option>
                <option value="Detectable">Negativo</option>
              </Select>
            </FormControl>

            <FormControl
              isInvalid={!!errors.sampleType}
              as={GridItem}
              colSpan={[12, 6]}
            >
              <FormLabel
                htmlFor="sampleType"
                fontSize="sm"
                fontWeight="md"
                color={'gray.700'}
              >
                Tipo de estudio
              </FormLabel>
              <Input
                type="text"
                focusBorderColor="brand.400"
                shadow="sm"
                size="sm"
                w="full"
                rounded="md"
                {...register('sampleType', {
                  required: 'This is required',
                })}
              />
            </FormControl>

            <GridItem colSpan={[24]} my={3}>
              <Text fontSize="lg" mb={2}>
                Resultado del procesamiento
                <StatusBadge
                  ml={[0, 2]}
                  fontSize="0.8rem"
                  status={data?.status as Status}
                />
              </Text>
              <Divider />
            </GridItem>

            <FormControl as={GridItem} colSpan={[12, 6]}>
              <FormLabel
                htmlFor="errorType"
                fontSize="sm"
                fontWeight="md"
                color={'gray.700'}
              >
                Tipo de error
              </FormLabel>
              <Input
                type="text"
                focusBorderColor="brand.400"
                shadow="sm"
                size="sm"
                w="full"
                rounded="md"
                readOnly
                defaultValue={data?.errorType}
              />
            </FormControl>

            <FormControl as={GridItem} colSpan={[18]}>
              <FormLabel
                htmlFor="errorResponse"
                fontSize="sm"
                fontWeight="md"
                color={'gray.700'}
              >
                Detalle del error
              </FormLabel>
              <Textarea
                type="text"
                focusBorderColor="brand.400"
                shadow="sm"
                size="sm"
                w="full"
                rounded="md"
                readOnly
                defaultValue={data?.errorResponse}
              />
            </FormControl>
          </SimpleGrid>
          <HStack
            justifyContent={'space-between'}
            alignItems={'center'}
            mt={10}
          >
            <Button
              ml={4}
              colorScheme="teal"
              variant="link"
              type="button"
              onClick={() => navigate('/')}
              leftIcon={<FiArrowLeft />}
            >
              Volver
            </Button>
            <Box>
              <Tooltip label="Solo se pueden reprocesar los que tengan error">
                <Button
                  colorScheme="orange"
                  isLoading={reprocessing}
                  type="button"
                  disabled={data?.status === 'PROCESS_OK'}
                  mr={2}
                  variant="ghost"
                  onClick={reProcess}
                >
                  Procesar nuevamente
                </Button>
              </Tooltip>
              <Button
                colorScheme="teal"
                isLoading={status === 'loading'}
                type="submit"
                disabled={data?.status === 'PROCESS_OK'}
              >
                Actualizar datos
              </Button>
            </Box>
          </HStack>
        </form>
      </Card>
    </Box>
  );
};

export default Detail;
