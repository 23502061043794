import { useEffect, useMemo, useReducer, useState } from 'react';
import { get, patch } from '../utils/fetchApi';

interface State {
  data?: UnitConversion;
  error?: Error;
  status: 'idle' | 'loading' | 'error' | 'fetched';
}

type Action =
  | { type: 'loading' }
  | { type: 'fetched'; payload: UnitConversion }
  | { type: 'error'; payload: Error };

const useDetail = (url: string) => {
  const initialState: State = {
    error: undefined,
    data: undefined,
    status: 'idle',
  };

  const fetchReducer = (state: State, action: Action): State => {
    switch (action.type) {
      case 'loading':
        return { ...initialState, status: action.type };
      case 'fetched':
        return { ...initialState, data: action.payload, status: action.type };
      case 'error':
        return { ...initialState, error: action.payload, status: action.type };
      default:
        return state;
    }
  };

  const [state, dispatch] = useReducer(fetchReducer, initialState);

  useEffect(() => {
    let didCancel = false;

    const fetchData = async () => {
      dispatch({ type: 'loading' });
      try {
        const response = await get<UnitConversion>(url);
        if (!response.ok) {
          throw new Error(response.statusText);
        }
        const data = response.data as UnitConversion;
        if (!didCancel) {
          dispatch({ type: 'fetched', payload: data });
        }
      } catch (error) {
        if (!didCancel) {
          dispatch({ type: 'error', payload: error as Error });
        }
      }
    };

    fetchData();

    return () => {
      didCancel = true;
    };
  }, [url]);

  return {
    ...state,
    dispatch,
  };
};

const updateData = async (url: any, values: any, dispatch: any) => {
  dispatch({ type: 'loading' });
  try {
    const response = await patch<PathResponse>(url, values);
    if (!response.ok) {
      throw new Error(response.statusText);
    }
    const res = response.data;
    if (res?.code !== 200) {
      throw new Error(res?.msg);
    }
    const data = res.data;
    dispatch({ type: 'fetched', payload: data });
  } catch (error) {
    dispatch({ type: 'error', payload: error as Error });
  }
};

export { useDetail, updateData };
