import { useEffect, useState } from 'react';
import {
  Box,
  Button,
  chakra,
  Checkbox,
  Flex,
  FormControl,
  FormLabel,
  GridItem,
  Input,
  InputGroup,
  InputLeftAddon,
  Select,
  SimpleGrid,
  Stack,
  Text,
  useColorModeValue,
} from '@chakra-ui/react';
import DatePicker from './DatePicker';
import Card from './Card';
import { initialFilters, useFilters } from '../context/SearchContext';
import { Range } from 'react-date-range';

const Filters = () => {
  const { filters, setFilters, cleanFilters } = useFilters();
  const [state, setState] = useState(filters);

  useEffect(() => {
    setState(filters);
  }, [filters]);

  const submit = (e: any) => {
    e.preventDefault()
    setFilters((current) => ({ ...current, ...state, page: 1 }));
  };

  const submitClean = () => {
    cleanFilters();
    setState({ ...initialFilters });
  };

  const setDate = (range: Range) => {
    setState((current) => ({ ...current, ...range }));
  };

  return (
    <form>
      <Card>
        <SimpleGrid
          display={{ base: 'initial', md: 'grid' }}
          columns={{ sm: 12, md: 24 }}
          spacing={{ md: 2 }}
        >
          <FormControl as={GridItem} colSpan={[12, 6]}>
            <FormLabel
              htmlFor="firstName"
              fontSize="sm"
              fontWeight="md"
              color={useColorModeValue('gray.700', 'gray.50')}
            >
              Nombre
            </FormLabel>

            <Input
              type="text"
              name="firstName"
              id="firstName"
              autoComplete="first-name"
              focusBorderColor="brand.400"
              shadow="sm"
              size="sm"
              w="full"
              rounded="md"
              value={state.firstName}
              onChange={(e) =>
                setState((current) => ({
                  ...current,
                  firstName: e.target.value,
                }))
              }
            />
          </FormControl>

          <FormControl as={GridItem} colSpan={6}>
            <FormLabel
              htmlFor="lastName"
              fontSize="sm"
              fontWeight="md"
              color={useColorModeValue('gray.700', 'gray.50')}
            >
              Apellido
            </FormLabel>
            <Input
              type="text"
              name="lastName"
              id="lastName"
              autoComplete="family-name"
              focusBorderColor="brand.400"
              shadow="sm"
              size="sm"
              w="full"
              rounded="md"
              value={state.lastName}
              onChange={(e) =>
                setState((current) => ({
                  ...current,
                  lastName: e.target.value,
                }))
              }
            />
          </FormControl>

          <FormControl as={GridItem} colSpan={4}>
            <FormLabel
              htmlFor="identificationNumber"
              fontSize="sm"
              fontWeight="md"
              color={useColorModeValue('gray.700', 'gray.50')}
            >
              DNI
            </FormLabel>
            <InputGroup size="sm">
              <InputLeftAddon
                children="DNI"
                bg={useColorModeValue('gray.50', 'gray.800')}
                color={useColorModeValue('gray.500', 'gay.50')}
                rounded="md"
              />
              <Input
                type="number"
                name="identificationNumber"
                id="identificationNumber"
                autoComplete="identificationNumber"
                focusBorderColor="brand.400"
                shadow="sm"
                size="sm"
                w="full"
                rounded="md"
                onChange={(e) =>
                  setState((current) => ({
                    ...current,
                    identificationNumber: e.target.value,
                  }))
                }
              />
            </InputGroup>
          </FormControl>

          <FormControl as={GridItem} colSpan={4}>
            <FormLabel
              htmlFor="status"
              fontSize="sm"
              fontWeight="md"
              color={useColorModeValue('gray.700', 'gray.50')}
            >
              Estado
            </FormLabel>
            <Select
              id=""
              name="status"
              autoComplete="status"
              focusBorderColor="brand.400"
              shadow="sm"
              size="sm"
              w="full"
              rounded="md"
              defaultValue={state.status}
              onChange={(e) =>
                setState((current) => ({
                  ...current,
                  status: e.target.value === 'ALL' ? undefined : e.target.value,
                }))
              }
            >
              <option value="ALL">Todos</option>
              <option value="PENDING">Pendientes</option>
              <option value="PROCESS_OK">Procesados</option>
              <option value="PROCESS_ERROR">Procesados con error</option>
              <option value="ERROR_ROSSI_SERVICES">
                Error en datos del estudio
              </option>
            </Select>
          </FormControl>
          <FormControl as={GridItem} colSpan={4}>
            <FormLabel
              htmlFor="laboratory"
              fontSize="sm"
              fontWeight="md"
              color={useColorModeValue('gray.700', 'gray.50')}
            >
              Laboratorio
            </FormLabel>
            <Select
              id=""
              name="laboratory"
              autoComplete="laboratory"
              focusBorderColor="brand.400"
              shadow="sm"
              size="sm"
              w="full"
              rounded="md"
              defaultValue={state.laboratory}
              onChange={(e) =>
                setState((current) => ({
                  ...current,
                  laboratory:
                    e.target.value === 'ALL' ? undefined : e.target.value,
                }))
              }
            >
              <option value="ALL">Todos</option>
              <option value="ROSSI">Rossi</option>
              <option value="HIDALGO">Hidalgo</option>
            </Select>
          </FormControl>
          <FormControl as={GridItem} colSpan={6}>
            <FormLabel
              htmlFor="barcode"
              fontSize="sm"
              fontWeight="md"
              color={useColorModeValue('gray.700', 'gray.50')}
            >
              Número de estudio
            </FormLabel>
            <Input
              type="text"
              name="barcode"
              id="barcode"
              autoComplete="family-name"
              focusBorderColor="brand.400"
              shadow="sm"
              size="sm"
              w="full"
              rounded="md"
              onChange={(e) =>
                setState((current) => ({ ...current, barcode: e.target.value }))
              }
            />
          </FormControl>

          <FormControl as={GridItem} colSpan={6}>
            <FormLabel
              htmlFor="barcode"
              fontSize="sm"
              fontWeight="md"
              color={useColorModeValue('gray.700', 'gray.50')}
            >
              Fecha de estudio
            </FormLabel>
            <DatePicker
              setDate={setDate}
              defaultStartDate={state.startDate}
              defaultEndDate={state.endDate}
            />
          </FormControl>

          <FormControl as={GridItem} colSpan={24}>
            <Flex alignItems="start" mt={2}>
              <Flex alignItems="center" h={5}>
                <Checkbox
                  id="withError"
                  rounded="md"
                  name="withError"
                  isChecked={state.withError}
                  onChange={(e) =>
                    setState((current) => ({
                      ...current,
                      withError: e.target.checked,
                    }))
                  }
                />
              </Flex>
              <Box ml={3} fontSize="sm">
                <chakra.label
                  htmlFor="withError"
                  fontWeight="md"
                  color={useColorModeValue('gray.700', 'gray.50')}
                >
                  Con error
                </chakra.label>
                <Text color={useColorModeValue('gray.500', 'gray.400')}>
                  Filtra por todos los tipos de error.
                </Text>
              </Box>
            </Flex>
          </FormControl>
        </SimpleGrid>
        <Stack
          justify="space-between"
          direction={{ base: 'row', md: 'column' }}
          spacing={4}
        >
          <Button
            as={'a'}
            fontSize={'sm'}
            fontWeight={400}
            variant={'link'}
            type="button"
            onClick={submitClean}
            sx={{
              cursor: 'pointer',
            }}
          >
            Limpiar filtros
          </Button>
          <Button
            type="submit"
            fontSize={'sm'}
            fontWeight={600}
            color={'white'}
            bg={'cyan.500'}
            onClick={submit}
            _hover={{
              bg: 'cyan.600',
            }}
          >
            Buscar estudios
          </Button>
        </Stack>
      </Card>
    </form>
  );
};

export default Filters;
