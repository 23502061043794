import {
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Icon,
  Box,
  Spinner,
  Stack,
  Text,
  Flex,
  Tooltip,
  IconButton,
} from '@chakra-ui/react';
import { useTable, Column, usePagination } from 'react-table';
import {
  FiEye,
  FiAlertCircle,
  FiChevronLeft,
  FiChevronRight,
  FiChevronsLeft,
  FiChevronsRight,
} from 'react-icons/fi';
import Card from '../components/Card';
import Filters from '../components/Filters';
import { useFilters } from '../context/SearchContext';
import { useEffect, useMemo } from 'react';
import { Link } from 'react-router-dom';
import StatusBadge from '../components/StatusBadge';

export type DataTableProps<Data extends object> = {
  data: Data[];
  columns: Column<object>[];
  error?: Error;
  totalElements: number;
  totalPages: number;
  pageNumber: number;
  size: number;
  status: 'idle' | 'loading' | 'error' | 'fetched';
};

function DataTable<Data extends object>({
  data,
  columns,
  status,
  totalElements,
  totalPages,
  pageNumber,
  size,
}: DataTableProps<Data>) {
  const { setFilters } = useFilters();

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    canPreviousPage,
    canNextPage,
    pageCount,
    nextPage,
    previousPage,
    gotoPage,
    state: { pageIndex },
  } = useTable(
    {
      columns,
      data,
      useControlledState: (state) => {
        return useMemo(
          () => ({
            ...state,
            pageIndex: pageNumber,
          }),
          [state]
        );
      },
      initialState: { pageIndex: pageNumber, pageSize: size },
      manualPagination: true,
      pageCount: totalPages,
    },
    usePagination
  );

  useEffect(() => {
    gotoPage(pageNumber);
  }, [pageNumber, gotoPage]);

  const handleNextPage = () => {
    nextPage();
    setFilters((current: any) => ({
      ...current,
      page: current.page + 1,
    }));
  };

  const handlePrevPage = () => {
    previousPage();
    setFilters((current: any) => ({
      ...current,
      page: current.page - 1,
    }));
  };

  const handleFirstPage = () => {
    gotoPage(0);
    setFilters((current: any) => ({
      ...current,
      page: 1,
    }));
  };

  const handleLastPage = () => {
    gotoPage(totalPages - 1);
    setFilters((current: any) => ({
      ...current,
      page: totalPages,
    }));
  };

  if (status === 'error') {
    return (
      <Stack justifyContent={'center'} alignItems={'center'} mt={8}>
        <Icon as={FiAlertCircle} color="red.500" w={12} h={12} />
        <Text fontSize="xl" fontWeight="bold">
          Ups! Algo salió mal
        </Text>
      </Stack>
    );
  }

  return (
    <Card>
      {status === 'loading' ? (
        <Stack justifyContent={'center'} alignItems={'center'} w="100%">
          <Spinner
            thickness="2px"
            speed="0.65s"
            emptyColor="gray.200"
            color="cyan.500"
            size="lg"
          />
        </Stack>
      ) : (
        <Box display={'flex'} flexDirection="column" width={'100%'}>
          <Table {...getTableProps()} size="sm">
            <Thead>
              {headerGroups.map((headerGroup: any) => (
                <Tr {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup.headers.map((column: any) => (
                    <Th
                      {...column.getHeaderProps()}
                      isNumeric={column.isNumeric}
                    >
                      {column.render('Header')}
                    </Th>
                  ))}
                </Tr>
              ))}
            </Thead>
            <Tbody {...getTableBodyProps()}>
              {page.map((row: any) => {
                prepareRow(row);
                return (
                  <Tr {...row.getRowProps()}>
                    {row.cells.map((cell: any) => (
                      <Td
                        {...cell.getCellProps()}
                        isNumeric={cell.column.isNumeric}
                      >
                        {cell.render('Cell')}
                      </Td>
                    ))}
                  </Tr>
                );
              })}
            </Tbody>
          </Table>
          <Flex justifyContent="space-between" m={4} alignItems="center">
            <Flex alignItems="center">
              <Text flexShrink="0" mr={8}>
                <Text fontWeight="bold" as="span">
                  {pageIndex * size + 1} - {pageIndex * size + page.length}
                </Text>{' '}
                de{' '}
                <Text fontWeight="bold" as="span">
                  {totalElements}
                </Text>{' '}
                estudios
              </Text>
            </Flex>
            <Flex>
              <Flex>
                <Tooltip label="Primera página">
                  <IconButton
                    aria-label="first"
                    onClick={handleFirstPage}
                    isDisabled={!canPreviousPage}
                    icon={<Icon as={FiChevronsLeft} h={3} w={3} />}
                    mr={4}
                  />
                </Tooltip>
                <Tooltip label="Página anterior">
                  <IconButton
                    aria-label="prev"
                    onClick={handlePrevPage}
                    isDisabled={!canPreviousPage}
                    icon={<Icon as={FiChevronLeft} h={3} w={3} />}
                  />
                </Tooltip>
              </Flex>
              <Flex alignItems="center">
                <Text flexShrink="0" mx={8}>
                  Página{' '}
                  <Text fontWeight="bold" as="span">
                    {pageIndex + 1}
                  </Text>{' '}
                  de{' '}
                  <Text fontWeight="bold" as="span">
                    {pageCount}
                  </Text>
                </Text>
              </Flex>

              <Flex>
                <Tooltip label="Página siguiente">
                  <IconButton
                    aria-label="next"
                    onClick={handleNextPage}
                    isDisabled={!canNextPage}
                    icon={<Icon as={FiChevronRight} h={3} w={3} />}
                  />
                </Tooltip>
                <Tooltip label="Última página">
                  <IconButton
                    aria-label="last"
                    onClick={handleLastPage}
                    isDisabled={!canNextPage}
                    icon={<Icon as={FiChevronsRight} h={3} w={3} />}
                    ml={4}
                  />
                </Tooltip>
              </Flex>
            </Flex>
          </Flex>
        </Box>
      )}
    </Card>
  );
}

const columns: Column<UnitConversion>[] = [
  {
    Header: 'Estudio',
    accessor: 'barcode',
    Cell: (props: any) => <b>{props.value}</b>,
  },
  {
    Header: 'Nombre',
    accessor: 'firstName',
    Cell: ({ value, row }) => (
      <span>
        {row.original.lastName} {value}
      </span>
    ),
  },
  {
    Header: 'DNI',
    accessor: 'identificationDocument',
  },
  {
    Header: 'Fecha',
    accessor: 'sampleDate',
  },
  {
    Header: 'Estado',
    accessor: 'status',
    Cell: ({ value }) => <StatusBadge status={value as Status} />,
  },
  {
    Header: 'Detalle',
    Cell: ({ row }: { row: any }) => (
      <Link to={`detail/${row.original.id}`}>
        <Icon as={FiEye} />
      </Link>
    ),
  },
];

export default function Home() {
  const { filters, state, getData } = useFilters();

  useEffect(() => {
    getData();
  }, []);

  return (
    <Box>
      <Filters />
      <DataTable
        columns={columns}
        error={state.error}
        status={state.status}
        data={state.data?.content || []}
        totalElements={state.data?.totalElements || 0}
        totalPages={state.data?.totalPages || 0}
        pageNumber={filters.page - 1}
        size={filters.size}
      />
    </Box>
  );
}
